import { useInfiniteQuery } from 'react-query';

import { orgs_history } from '../constants/Keys';

import { getDonationHistory as getOrgs } from '../global/helpers';

export const useFetchOrgs = (params) => {
  const getDonationHistory = async ({ pageParam = 0 }) => {
    return getOrgs({ ...params, offset: pageParam }).then((resp) => resp.data);
  };

  return useInfiniteQuery(orgs_history, getDonationHistory, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.data.length === 0) return undefined;
      if (lastPage.data.length <= 10) {
        return pages[pages.length - 1].offset + lastPage.data.length;
      }
    },
    retry: false,
  });
};
