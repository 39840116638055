export let lorem = "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cud sociis natoque penatibus etma. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cud sociis natoque penatibus eta.";

/* Section: LocalStorage Keys */
export let KEY_USER_TYPE = "KEY_USER_TYPE";
export let KEY_ACCESS_TOKEN = "KEY_ACCESS_TOKEN";
export let KEY_REFRESH_TOKEN = "KEY_REFRESH_TOKEN";


/* Section: Constants */
export let USER_TYPE_ORG = "org";
export let USER_TYPE_CONTRIB = "contrib";
export let USER_TYPE_ADMIN = "admin";
export let USER_TYPE_ALL = "USER_TYPE_ALL"; //internal usage not from API 

export let TYPE_PROJECT = "project";
export let TYPE_SADAQA = "sadaqa";

/* Section: APIS */

export const BASE_URL = process.env["REACT_APP_BASE_URL_".concat(process.env.REACT_APP_ENV.toUpperCase())] + "/v1";

export let USERS = BASE_URL + "/auth/users";
export let SIGN_IN = BASE_URL + "/auth/signIn";
export let PROFILE = BASE_URL + "/auth/profile"
export let PASSWORD = BASE_URL + "/auth/password"
export let REGISTER_ORG = BASE_URL + "/auth/registerOrg";
export let REGISTER_USER = BASE_URL + "/auth/registerUser"

export let STATES = BASE_URL + "/static/states";
export let SUPPORTING_CAUSES = BASE_URL + "/static/supportingCauses";

export let UPDATES = BASE_URL + "/updates";
export let UPLOAD = BASE_URL + "/media/upload";

export const MEDIA = BASE_URL + "/media";

export let PROJECTS = BASE_URL + "/projects";
export let TRENDING_PROJECTS = BASE_URL + "/trending/projects";

export let REVIEWS = BASE_URL + "/reviews";
export let CONTRIBUTORS = BASE_URL + "/contributors";

export let DONATIONS = BASE_URL + "/donations";

export let ORGANIZATIONS = BASE_URL + "/orgs";
export let TRENDING_ORGANIZATIONS = BASE_URL + "/trending/orgs";

export let DASHBOARD = BASE_URL + "/dashboard";
export let DASHBOARD_HEADERS = DASHBOARD + "/headers";
export let DASHBOARD_DONATIONS_PER_PROJECT = DASHBOARD + "/donationsPerProject";
export let DASHBOARD_DONATIONS_PER_ORG = DASHBOARD + "/donationsPerOrg";
export let DASHBOARD_OVERALL_DONATIONS = DASHBOARD + "/overallDonations";
