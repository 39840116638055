import React from 'react';
import { Row } from 'react-bootstrap';
// import donateOrgVector from '../../../images/oragnization_test.png';
import money from '../../../images/money.svg';
import './DonateOrganizationItem.css';
import { BASE_URL } from '../../../constants/Constants';

const DonateOrganizationItem = (props) => {
    const { orgName, amountText, recurringTypeText, nextDonationDate, image } = props;
    let src = image ? `${BASE_URL}/media/${image}/200` : image ;

    return (
        <div className='donate-organization-item'>
            <Row className="donate-organization-item-row">
                <div className="donate-org-col-one">
                    <img src={src} alt='' className='donate_org_logo' />
                </div>
                <div className="donate-org-col-two">
                    <p className="donate_org_name">{orgName ? orgName : '-'}</p>
                    <div className="donate_value-div">
                        <img src={money} alt="money" className="org_money_img mr-1" />
                        <p className="org_donate_value-p">{amountText ? amountText : '-'}</p>
                    </div>
                </div>
                <div className="donate-org-col-three">
                    <p className="donate_badge_status">{recurringTypeText ? recurringTypeText : '-' }</p>
                    <div className="org_donation_date-div">
                        <p className="org_donation_date-lbl">Next Donation:</p>
                        <p className="org_donation_date-txt">{nextDonationDate ? nextDonationDate : '-'}</p>
                    </div>
                </div>
            </Row>
        </div>
    )
}

export default DonateOrganizationItem;
