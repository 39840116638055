import React from 'react';
import Logo from '../../images/logo.png';
import "./ComingSoon.css";

const ComingSoon = () => {
    return (
        <section id='coming_soon_main'>
            <div className='coming_soon-section'>
                <div className='coming_soon-inner'>
                    <div className='coming_soon-item'>
                        <div id='coming_soon_top_content-div'>
                            <div className='text-center'>
                                <img src={Logo} className='mx-auto sadaqa-logo' alt='' />
                            </div>
                        </div>
                        <div id='coming_soon_body-div'>
                            <div className='container'>
                                <div className='col-lg-12'>
                                    <div className='text-center'>
                                        <h3 className='main-title'>Our Website is coming soon</h3>
                                        <p className='main-desc'>
                                            We're getting ready to launch in!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ComingSoon;
