import React, { useState, useEffect } from 'react';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import DropDownItem from './DropDownItem';
import './RecurringDonationsStep1.css';
import './SelectSearchStyle.css';
import './DropDownItem.css';
import { SelectedDonationItem } from './RecurringDonations';
import { getAutocomplete } from '../../../global/helpers';

import { AutocompleteKey } from '../../../constants/Keys';
import { useQueryClient } from 'react-query';
import { BASE_URL } from '../../../constants/Constants';

import { getImages as getById } from '../../../global/helpers';

import defaultImage from '../../../images/img-default.svg';

export const initialRadios = [
  { key: 'day', value: 'Daily' },
  { key: 'week', value: 'Weekly' },
  { key: 'month', value: 'Monthly' },
];

const RecurringDonationsStep1 = (props) => {
  const { data, setData } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [errorRecurring, setErrorRecurring] = useState('');
  const { orgId, projectId } = useParams();
  useEffect(() => {
    if (props.selectedItem) setErrorMessage('');
  }, [props.selectedItem]);

  useEffect(() => {
    let url;

    if (orgId) {
      url = `${BASE_URL}/orgs/${orgId}`;
      getById(url).then((resp) => setData(resp.data));
    }
    if (projectId) {
      url = `${BASE_URL}/projects/${projectId}`;
      getById(url).then((resp) => setData(resp.data));
    }
  }, [orgId, projectId, setData]);

  const handleNext = (e) => {
    e.preventDefault();
    if (props.recurring === 'on' || props.recurring === 'select...') {
      setErrorRecurring('Please select a recurring type...');
      return;
    }

    if (orgId || projectId) {
      props.nextBtn();
    }

    if (!props.selectedItem) {
      setErrorMessage('Please Select an organization or a cause');
      return;
    } else {
      setErrorMessage('');
      setErrorRecurring('');
      props.nextBtn();
    }
  };

  const [input, setInput] = useState('');
  const queryClient = useQueryClient();
  const handleSearch = async (e) => {
    const currentValue = e.target.value;
    setInput(currentValue);
    getAutocomplete('all', currentValue).then((resp) => {
      queryClient.setQueryData(AutocompleteKey, () => [...resp.data]);
    });
  };

  const formatOthers = (e) => {
    // Prevent dots (".")
    let checkIfDot;
    if (e.key !== undefined) {
      // Check if it's "."
      checkIfDot = e.key === ".";
    }
    return checkIfDot && e.preventDefault();
  }

  return (
    <React.Fragment>
      {orgId || projectId ? (
        <React.Fragment>
          <div style={{ marginBottom: 35 }}>
            <h3 className='donate-h3'>You’re supporting</h3>
            <div style={{ display: 'flex' }}>
              {data ? (
                <React.Fragment>
                  <img
                    src={`${!data.image ? defaultImage : `${BASE_URL}/media/${data.image}/100`}`}
                    className='donate_org_vector'
                    alt=''
                  />
                  {orgId ? (
                    <h3 className='org_name-h3'>{data.name}</h3>
                  ) : (
                    <div className='d-flex flex-column ml-3 mt-2'>
                      <h3 className='tracking-normal text-sm text-normal '>{data.description}</h3>
                      <h3 className='tracking-normal text-sm text-normal  mt-2'>
                        <span style={{ color: '#90AD98' }} className='font-weight-normal mr-2'>
                          Created By
                        </span>{' '}
                        {data.byOrg}
                      </h3>
                    </div>
                  )}
                </React.Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
          <Form.Check
            type='radio'
            label='One Time'
            name='formHorizontalRadios'
            id='oneTime'
            value={null}
            className='recuring_type_opt'
            defaultChecked={!props.recurring}
            onChange={(e) => {
              props.setRecurring(e.target.value);
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 20,
            }}
          >
            <Form.Check
              type='radio'
              label='Set Recurring'
              defaultChecked={!!props.recurring}
              name='formHorizontalRadios'
              id='setRecurring'
              className='recuring_type_opt'
              onChange={(e) => {
                props.setRecurring(e.target.value);
              }}
            />
            <select
              hidden={!props.recurring}
              className='select_donate_type'
              disabled={!props.recurring}
              value={props.recurring}
              onChange={(e) => {
                props.setRecurring(e.target.value);
                setErrorRecurring('');
              }}
            >
              {' '}
              <option>select...</option>
              {initialRadios.map((radio) => {
                return <option value={radio.key}>{radio.value}</option>;
              })}
            </select>
          </div>
          {
            <p className='text-danger text-normal tracking-normal text-sm my-2'>
              {errorRecurring ? errorRecurring : ''}
            </p>
          }
        </React.Fragment>
      ) : (
        <>
          <div className='recurring-item-title'>Choose an organization or cause</div>
          <div
            style={{
              marginTop: '11px',
            }}
            className='custom-scrollable'
          >
            <SelectSearch
              search
              renderOption={DropDownItem}
              options={
                props.items &&
                props.items.map((item) => {
                  return {
                    name: item.title,
                    value: item.type + '-' + item.id,
                    object: item,
                    image: `${BASE_URL}/media/${item.image}/200`,
                  };
                })
              }
              placeholder='Search to Select'
              emptyMessage='Not Found'
              onChange={(s1, s2, s3) => {
                props.setSelectedItem(s2.object);
              }}
              filterOptions={fuzzySearch}
              renderValue={(valueProps) => (
                <input
                  {...valueProps}
                  onChange={handleSearch}
                  value={input}
                  className='select-search__input'
                />
              )}
            />
          </div>
          {errorMessage ? (
            <p className='mt-2 text-danger text-sm tracking-normal text-normal font-weight-normal'>
              {errorMessage}
            </p>
          ) : (
            ''
          )}
          {props.selectedItem ? (
            <SelectedDonationItem
              title={props.selectedItem.title}
              description={props.selectedItem.description}
              type={props.selectedItem.type}
              image={props.selectedItem.image}
              by={props.selectedItem.by}
            />
          ) : null}

          {props.recurring ? (
            <>
              <div className='recurring-item-title' style={{ marginTop: '20px' }}>
                Set recurring payment schedule
              </div>

              <div style={{ marginTop: '11px', width: '100%' }}>
                <ToggleButtonGroup
                  name='payment'
                  defaultValue={props.recurring}
                  className='custom-radio custom-radio-schedule'
                >
                  {initialRadios.map((radio, idx) => (
                    <ToggleButton
                      key={idx}
                      type='radio'
                      name='radio'
                      varient='outline-success'
                      value={radio.key}
                      onChange={(e) => {
                        props.setRecurring(e.target.value);
                      }}
                    >
                      {radio.value}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </div>
            </>
          ) : null}
        </>
      )}

      <div className='recurring-item-title' style={{ marginTop: '20px' }}>
        Select Amount
      </div>

      <div style={{ marginTop: '11px', width: 'calc(100% + 10px)' }}>
        <ToggleButtonGroup
          onChange={(amount) => {
            props.setAmount(amount);
          }}
          type={'radio'}
          defaultValue={props.amount}
          value={props.amount}
          name='amount'
          className='custom-radio custom-radio-amount-container'
          style={{
            maxWidth: '100%',
          }}
        >
          <ToggleButton
            className='mb-2 custom-amount-radio'
            id='toggle-check1'
            variant='outline-primary'
            value={10}
            onChange={(e) => {
              props.setAmount(e.currentTarget.value);
            }}
          >
            $10
          </ToggleButton>
          <ToggleButton
            className='mb-2 custom-amount-radio'
            id='toggle-check1'
            variant='outline-primary'
            value={25}
            onChange={(e) => {
              props.setAmount(e.currentTarget.value);
            }}
          >
            $25
          </ToggleButton>
          <ToggleButton
            className='mb-2 custom-amount-radio'
            id='toggle-check'
            variant='outline-primary'
            value={50}
            onChange={(e) => {
              props.setAmount(e.currentTarget.value);
            }}
          >
            $50
          </ToggleButton>
          <ToggleButton
            className='mb-2 custom-amount-radio'
            id='toggle-check'
            variant='outline-primary'
            value={100}
            onChange={(e) => {
              props.setAmount(e.currentTarget.value);
            }}
          >
            $100
          </ToggleButton>
          <ToggleButton
            className='mb-2 custom-amount-radio'
            id='toggle-check'
            variant='outline-primary'
            value={250}
            onChange={(e) => {
              props.setAmount(e.currentTarget.value);
            }}
          >
            $250
          </ToggleButton>
          <ToggleButton
            className='mb-2 custom-amount-radio'
            id='toggle-check'
            variant='outline-primary'
            value={500}
            onChange={(e) => {
              props.setAmount(e.currentTarget.value);
            }}
          >
            $500
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className='other-container'>
        <div className='recurring-item-title mb-2'>
          Enter amount in whole numbers
        </div>
        <div className='other-text'>Other</div>
        <Form.Control
          type='number'
          name='others'
          className='other-input'
          onChange={(e) => {
            const currentValue = e.target.value;
            let amount = 10;
            if (currentValue.length > 0) {
              const parsedAmount = parseInt(e.target.value);
              if (parsedAmount > 10000) {
                e.target.value = 10000;
              } else if (parsedAmount < 1) {
                e.target.value = 1;
              }
              amount = e.target.value;
            }
            props.setAmount(amount);
          }}
          value={props.amount}
          onKeyDown={formatOthers}
        />
      </div>
      <div style={{ marginTop: '11px' }}>
        <button className='next-btn' onClick={handleNext}>
          Next
        </button>
      </div>
    </React.Fragment>
  );
};

export default RecurringDonationsStep1;
