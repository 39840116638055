import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import Green from '../../../Icons/account-green.png';
//import Profile from '../../../Icons/account-profile.png';
import imgDefault from '../../../images/img-default.svg';

/* import AddPaymentMethod from '../../../components/reusableComponents/AddPaymentMethod/AddPaymentMethod';
import { Modal } from 'react-bootstrap'; */
// import PaymentAlert from './PaymentAlert';

/* import BankAccount from './BankAccount'; */
import { getProfile } from '../../../global/helpers';
import { BASE_URL } from '../../../constants/Constants';

function AdminAccount(props, ref) {
  /* const [isOpen, setIsOpen] = useState(false); */
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [profileImg, setProfileImg] = useState(null);
  const [imgLoaded, setImgLoaded] = useState(false);

  useEffect(() => {
    getProfileLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      getProfileFromOutside() {
        getProfileLocal();
      },
    }),
    []
  );

  function getProfileLocal() {
    getProfile().then((response) => {
      if (response !== undefined) {
        if (response.status === 200) {
          let profile = response.data;
          let profileImgLocal = imgDefault;
          if (
            profile.imageGuid !== undefined &&
            profile.imageGuid !== null &&
            profile.imageGuid !== '' &&
            profile.imageGuid !== 'guid'
          ) {
            let imgUrl = BASE_URL + '/media/' + profile.imageGuid + '/200'; //first field: GUID, second is: size
            profileImgLocal = imgUrl;
          }
          setTimeout(() => {
            setProfileImg(profileImgLocal);
          }, 200);

          let name = profile?.firstName !== null ? profile?.firstName + ' ' : '';
          name += profile?.lastName !== null ? profile?.lastName : '';
          setName(name);
          setEmail(profile.email);
        }
      }
    });
  }

  const addDefaultSrc = (ev) => {
    ev.target.src = imgDefault;
    setImgLoaded(true);
  };

  let displayImg = imgLoaded ? 'inherit' : 'none';

  return (
    <div className='bg-white p-5 rounded shadow relative' style={{ zIndex: '1' }}>
      <img
        src={Green}
        alt='green'
        className='absolute'
        style={{ top: '0px', left: '0px', zIndex: '-1' }}
      />
      <div className='container'>
        <div className='row text-center text-lg-left'>
          {!imgLoaded ? <div className='account-img' style={{ backgroundColor: 'white' }} /> : null}
          <img
            style={{ display: displayImg, objectFit: 'contain', backgroundColor: 'white' }}
            className='account-img'
            src={profileImg}
            alt='Profile'
            onError={addDefaultSrc}
            onLoad={() => {
              setImgLoaded(true);
            }}
          />

          {/* <img
            className='col-lg-3 col-sm-6 '
            src={profileImg}
            alt='profile'
            onError={addDefaultSrc} /> */}
          <div className='offset-lg-1 col-lg-4 mt-lg-4 mt-5   '>
            <h1 className='tracking-normal text-normal mb-3 text-md'>{name}</h1>
            <p className='tracking-normal text-dark text-normal font-weight-normal text-md'>
              {email}
            </p>
          </div>
          {/* <div className='mt-lg-1 text-center text-lg-left col-lg-6 offset-lg-5 '>
            {
              //<PaymentAlert />
            }
            <p className='text-sm font-weight-normal tracking-normal' style={{ color: '#90AD98' }}>
              Default
            </p>
            <BankAccount textInfo='Bank account ending in 8371' isDefault={true} />
            <p className='text-sm font-weight-normal tracking-normal' style={{ color: '#90AD98' }}>
              Other Payment Method(s)
            </p>
            <BankAccount textInfo='Bank account ending in 8372' isDefault={false} />
            <BankAccount textInfo='Bank account ending in 8373' isDefault={false} />
            <BankAccount textInfo='Bank account ending in 8374' isDefault={false} />
            <button
              className='btn text-normal font-semi text-sm tracking-normal rounded py-1 px-3 px-lg-4 mt-4'
              style={{ color: '#7CB1FF', border: '2px solid #7CB1FF', width: '100%' }}
              onClick={() => setIsOpen(true)}
            >
              + Add Payment Method
            </button>
          </div> */}
        </div>
      </div>
      {/* <Modal show={isOpen} onHide={setIsOpen} centered>
        <AddPaymentMethod />
      </Modal> */}
    </div>
  );
}

export default forwardRef(AdminAccount);
