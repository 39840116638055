import fetchData from "../axios";

export const getDonorsHistory = ({limit, offset, projects}) => {
    return fetchData.get('/donations/donorsHistory', {
        params: {
            projects: projects,
            limit: limit,
            offset: offset
        }
    })
}
