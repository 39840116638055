import {useQuery} from "react-query";
import {getDonorsHistory} from "../../services/donations";
import styles from './DonationsHistoryTab.module.css'
import Money from '../../images/money.svg';
import * as Constants from "../../constants/Constants";
import InfiniteScroll from "react-infinite-scroll-component";
import React, {useEffect, useState} from "react";
import {SpinnerDiv} from "../SpinnerDiv";
import Button from "react-bootstrap/Button";
import errorImage from "../../images/image-not-found.png";

const DonorHistoryOrgListItem = ({details}) => {

    const [src, setSrc] = useState(Constants.BASE_URL + "/media/" + details.profileImage + "/200");
    const IMAGE_WIDTH = 70;
    const BORDER_RADIUS = IMAGE_WIDTH / 2;
    return (
        <div style={{display: 'flex', justifyContent: 'space-between'}} className={styles.donationsHistoryTab}>
            <div style={{marginRight: "auto"}} className='d-flex align-items-center'>
                <img style={{borderRadius: BORDER_RADIUS}}
                     src={src}
                     onError={()=>{
                        setSrc(errorImage);
                     }}
                     width={IMAGE_WIDTH}/>
                <h1 className='ml-2 ml-md-4 tracking-normal text-normal text-md font-weight-semi mb-0'>
                    {details.fullName}
                </h1>
            </div>
            <div style={{marginLeft: "auto"}} className='d-flex align-items-center'>
                <h1 className='mr-md-5 mr-2 tracking-normal text-normal text-md font-weight-semi mb-0'>${details.amount}</h1>
                <img src={Money} alt='money'/>
            </div>
        </div>
    )
}

const DonorsHistoryTab = ({isProjects = false}) => {
    const LIMIT = 10;
    const OFFSET_BAND = LIMIT;
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [items, setItems] = useState([]);
    const {data: response, isError, refetch, isLoading, isFetching} = useQuery("getDonorsHistory", () => getDonorsHistory({
        limit: LIMIT,
        offset: offset,
        projects: isProjects
    }), {
        refetchOnMount: false,
        refetchOnWindowFocus: false
    })

    useEffect(() => {
        refetch();
    }, [offset]);

    useEffect(() => {
        if (response?.data?.data) {
            if (response.data.data.length < LIMIT) {
                setHasMore(false);
            }
            const newData = [...items];
            newData.push(...response.data.data);
            setItems(newData);
        }
    }, [response?.data?.data]);

    const fetchNext = () => {
        setOffset(offset + OFFSET_BAND);
    }

    if (isLoading) {
        return <div>Loading...</div>
    }
    if (isError) {
        return <div>Error</div>
    }

    return <div id={"scrollableDiv"} style={{
        height: "30",
        overflow: 'auto',
    }}>
        {response.data && <InfiniteScroll
            dataLength={items.length}
            next={fetchNext}
            hasMore={hasMore}
            endMessage={
                <p style={{textAlign: 'center'}}>
                    {response.data.data.length === 0 ? <b>No data</b> : <b>No more data</b>}
                </p>
            }
            scrollableTarget={"scrollableDiv"}
        >
            {
                items.map(donor => {
                    return <DonorHistoryOrgListItem key={donor.id} details={donor}/>
                })
            }
        </InfiniteScroll>}

        {
            isFetching ? <SpinnerDiv/> :
            <div style={{textAlign: "center"}}>
                {hasMore ? <Button className={styles.showMoreBtn} onClick={() => fetchNext()}>Show more</Button> : null}
            </div>
        }
    </div>
}
export default DonorsHistoryTab;
