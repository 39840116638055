import axios from 'axios';

import { BASE_URL } from '../constants/Constants';

const fetchData = axios.create({
  baseURL: BASE_URL,
});

fetchData.interceptors.request.use(function (config) {
  const token = localStorage.getItem('KEY_ACCESS_TOKEN');
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

fetchData.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.message.includes('401')) {
      localStorage.clear();
      window.location = '/login';
    }
  }
);

export default fetchData;
