import React, { useState } from "react";
import OrganizationTab from "./OrganizationTab";
/* import ProjectsTab from "../reusableComponents/ProjectsTab/ProjectsTab"; */
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./DonationsHistoryTabs.css";
import MyProjects from "../../pages/Organization/Projects/MyProjects/MyProjects";
import DonorsHistoryTab from "./DonorsHistoryTab";
import * as helper from "../../global/helpers";

const DonationsHistoryTabs = (props) => {
    const [tabIndex, setTabIndex] = useState(0);
    return (
        <Tabs
            selectedIndex={tabIndex}
            onSelect={tabIndex => setTabIndex(tabIndex)}
            className="donations_tabs"
        >
            <TabList>
                <Tab>Organization</Tab>
                <Tab>Projects</Tab>
            </TabList>
            <TabPanel>
                {helper.isOrg() ? <DonorsHistoryTab/> : <OrganizationTab/>}
            </TabPanel>
            <TabPanel>
                <div className="mt-5">
                    <MyProjects isDonation={true} />
                </div>
            </TabPanel>
        </Tabs>
    );
};

export default DonationsHistoryTabs;
