import React from 'react';
import { Row } from 'react-bootstrap';
// import donateProjectVector from '../../../images/projectItemImage.png';
import money from '../../../images/money.svg';
import './DonateProjectItem.css';
import { BASE_URL } from '../../../constants/Constants';

const DonateProjectItem = (props) => {
    const { projectName, amountText, recurringTypeText, nextDonationDate, image, by } = props;
    let src = image ? `${BASE_URL}/media/${image}/200` : image ;

    return (
        <div className='donate-project-item'>
            <Row className="donate-project-item-row">
                <div className="donate-project-col-one">
                    <div 
                        className="conatiner"
                        style={{ 
                            position: 'relative',
                            width: 96,
                            height: 96,
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}>
                        <img
                            src={src}
                            alt=""
                            className='donate_project_logo'
                        />         
                        <div className="donate_project_logo_overlay">
                            <div className="donate_project_logo_overlay-text mx-auto">
                                {recurringTypeText ? recurringTypeText : '-'}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="donate-project-col-two">
                    <p className="donate_project_name">{projectName ? projectName : '-'}</p>
                    <p className="donate_by">by<span className="donate_by_span">{by ? by : '-'}</span></p>
                    <div className="donate_value-div">
                        <img src={money} alt="money" className="project_money_img mr-2" />
                        <p className="donate_value-p">{amountText ? amountText : '-'}</p>
                    </div>
                </div>
                <div className="donate-project-col-three">
                    <div className="project_donation_date-div">
                        <p className="project_donation_date-lbl">Next Donation:</p>
                        <p className="project_donation_date-txt">{nextDonationDate ? nextDonationDate : '-'}</p>
                    </div>
                </div>
            </Row>
        </div>
    )
}

export default DonateProjectItem;